.container {
  @include breakpoint(xs) {
    padding-left: $padding-small-horizontal * 2;
    padding-right: $padding-small-horizontal * 2;

    .container {
      //padding-left: $padding-base-horizontal;
      //padding-right: $padding-base-horizontal;
    }
  }

  @media (min-width: $screen-xl-min) {
    width: 1262px;
  }

  .container,
  .container-fluid {
    width: auto;
    margin-left: - $padding-small-horizontal * 2;
    margin-right: - $padding-small-horizontal * 2;
  }
}

.row {
  @include breakpoint(xs) {
    margin-left: - $padding-small-horizontal * 2;
    margin-right: - $padding-small-horizontal * 2;
  }
}

[class^="col-"] {
  @include breakpoint(xs) {
    padding-left: $padding-small-horizontal * 2;
    padding-right: $padding-small-horizontal * 2;
  }
}
